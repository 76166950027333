import React, { useEffect, useState, createContext } from "react";
import SingleUpComingEvent from "../reusableComponents/SingleUpComingEvent";
import { gurudwaraMalaysia } from "../reusableComponents/DataApi";
import BannerUsable from "../reusableComponents/BannerUsable";
import { home } from "../reusableComponents/DataApi";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRightCircle, Justify } from "react-bootstrap-icons";
import CancelIcon from '@mui/icons-material/Cancel';
import url from "../../url";
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import modalImg from "../../guruwara-pic.jpg"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@emotion/styled'
import { IconButton, Stack } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AddChairman = createContext();

const Home = () => {
  // const [donateesData, setDonateesData] = useState([]);
  // const [eventsData, setEventsData] = useState([]);
  // const apiUrl = `${url}/api/v1/donation`;
  // const navigate = useNavigate();
  // const getData = async () => {
  //   try {
  //     const apiData = await axios.get(apiUrl);
  //     // console.log(apiData.data.data);
  //     setDonateesData(apiData.data.data);
  //     // console.log(apiData.data.data);
  //     // console.log("donatees data", donateesData);
  //   } catch (error) {
  //     // console.log(error.msg);
  //   }
  // };
  // const apiUlrLiveEvents = `${url}/api/v1/events/current/month`;
  // const getEventData = async () => {
  //   try {
  //     const apiData = await axios.get(apiUlrLiveEvents);
  //     // console.log(apiData.data.data);
  //     setEventsData(apiData.data.data);
  //     // console.log(apiData.data.data);
  //     // console.log("events data .....", eventsData);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  // const [hukumnamaData, setHukumnanaData] = useState([]);
  // const getHukumnamaData = async () => {
  //   try {
  //     const hukumnamaApiData = await axios.get(`${url}/api/v1/hukamnama`);
  //     console.log(hukumnamaApiData.data.data);
  //     setHukumnanaData(hukumnamaApiData.data.data);
  //     // console.log(".......data of hukumnama.....", hukumnamaData);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  // const hukamNamaImg = () => {
  //   navigate(`/hukamnama/${hukumnamaData.photo}`);
  // };

  // const date = new Date();
  // const day = `${date.getDate()}`
  // console.log("day of date...", date.getDate());
  // const month = `${(date.getMonth() + 1)}`
  // let dayNum = "";
  // let monthNum = "";
  // if (day.length === 1) {
  //   dayNum = `0${day}`
  // }
  // if (month.length === 1) {
  //   monthNum = `0${month}`
  // }
  // //  let currentDate="";
  // let currentDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  // //  currentDate = `${dayNum}/${monthNum}/${date.getFullYear()}`;


  // useEffect(() => {
  //   getData();
  //   getEventData();
  //   getHukumnamaData();
  // }, []);



  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // // const handleShow = () => setShow(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  const style = {
    position: 'absolute',
    fontFamily: "sans-serif",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: "70%", sm: "80%", xs: "85%" },
    // height: '44.37em',
    // height: { xs: "30%", sm: "40%", md: "60%", lg: "80%" },
    // overflowY: "scroll",
    // overflowY:"hidden",

    background: 'linear-gradient(to left top, #e15817, #c44621, #a53724, #862a24, #662020)',
    // border: '2px solid #000',
    boxShadow: 14,
    p: { xs: 2, md: 4 },
    borderRadius: "20px",
    color: "rgb(230, 230, 230)",
    textShadow: "1px 1px 6px #121211"
  };

  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>

      {/* <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >

            <Typography id="modal-modal-title" variant="h6" sx={{ fontSize: { xs: "14px", sm: "18px" } }} >
              🙏Waheguru Ji Ka Khalsa Waheguru Ji Ki Fateh 🙏
            </Typography>
            <IconButton onClick={handleClose}>
              <CancelIcon sx={{ color: "white" }} />
            </IconButton>

          </Stack>
          <Stack sx={{
            maxHeight: { sm: "400px", xs: "300px", md: "500px" }, overflowY: "auto", "&::-webkit-scrollbar": {
              width: "3px",
              bgcolor: "transparent",
              borderRadius: "6px"
            },
            "&::-webkit-scrollbar-thumb": {
              bgcolor: "white",
              height: "25%",
              borderRadius: "6px"
            },
          }} >

            <Typography>
              Sikh Brothers & Sisters Ji.<br />
              Gurdwara Sahib Putrajaya Malaysia<br />
              National Gurdwara of Malaysia.<br />
            </Typography>
            <Typography>On behalf of the Management Committee I humbly request you to join us on the “Sikh Road 550 to Putrajaya “ in bringing the Sacred Gurbani of Guru Nanak Dev Ji Maharaj to Putrajaya Malaysia.<br />
              The noble project to build the Gurdwara Sahib was officially  launched on 8 Dec 2019. ( 550 Guru Nanak Prakash Year) on a prime piece of land in Presint 5/20 provided by the Government of Malaysia.</Typography>
            <Typography>Due  to  Covid-19 the Project got  delayed but now with Waheguru’s blessings is back on track. The authorities require us to show a strong membership  base and at least RM One million( 20% of the projected cost) before they grant us permission to start construction. This is the one and only opportunity for the present Sangat of Malaysia to leave a Nishan Sahib In Putrajaya  (the Administrative Capital of Malaysia)  for our future generation to have a place of worship and religious activities.</Typography>
            <Typography>I humbly request all Malaysian Sikhs and Sikhs abroad to become life members of Gurdwara Sahib Putrajaya Malaysia and help complete the “Sikh Road to Putrajaya”
              Please forward this message and Membership/ Donation forms to all Sikhs and Non Sikhs around the globe.</Typography>
            <Typography>
              This SEWA of encouraging the Sikh Sangat to become life members for a noble cause of spreading the Guru’s Bani is an act of love for our Sri Guru Granth Sahib Ji.<br />
              “Wich Duniya  Sev Kamiyeh ta’n Dargeh Baisen  Payieh”<br />
              Thank you Ji and we look forward to your support both moral and financial to help realise this noble Project.
            </Typography>
            <Typography>
              Thank you Ji.<br />
              Waheguru bless 🙏<br /><br />
              Sarbat Dha Bhala<br />
              Maj(Rtd)Jagindar Singh RMAF<br />
              President<br />
              GSPutrajaya Society (GSPS)Malaysia<br />
              Mobile +6019 2705500
            </Typography>
          </Stack>
        </Box>
      </Modal> */}

      <BannerUsable data={home} />
      <div className="container-fluid">
        <div className="row d-flex justify-content-evenly pt-5">
          {/* ---------Address by the Chairman------ */}
          {/* <div className="col-md-11 text-start p-0"  style={{ marginBottom: "50px", }}>
          <Accordion sx={{background: "#5333dd"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{color:"#ffffff"}} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className=" text-start fs-2 fw-bolder" sx={{color:"#ffffff"}}>Address by the Chairman</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{p:6, color:"rgba(255,255,255,0.8)", fontSize:"18px", textAlign: "justify"}}>
              <Typography className=" fs-4 " sx={{textAlign: "center", color:"#ffffff"}}>
              🙏Waheguru Ji Ka Khalsa Waheguru Ji Ki Fateh 🙏
              </Typography>
              <Typography>
                Sikh Brothers & Sisters Ji.<br />
                Gurdwara Sahib Putrajaya Malaysia<br />
                National Gurdwara of Malaysia.<br />
              </Typography>
              <Typography>On behalf of the Management Committee I humbly request you to join us on the “Sikh Road 550 to Putrajaya “ in bringing the Sacred Gurbani of Guru Nanak Dev Ji Maharaj to Putrajaya Malaysia.<br />
                The noble project to build the Gurdwara Sahib was officially  launched on 8 Dec 2019. ( 550 Guru Nanak Prakash Year) on a prime piece of land in Presint 5/20 provided by the Government of Malaysia.</Typography>
              <Typography>Due  to  Covid-19 the Project got  delayed but now with Waheguru’s blessings is back on track. The authorities require us to show a strong membership  base and at least RM One million( 20% of the projected cost) before they grant us permission to start construction. This is the one and only opportunity for the present Sangat of Malaysia to leave a Nishan Sahib In Putrajaya  (the Administrative Capital of Malaysia)  for our future generation to have a place of worship and religious activities.</Typography>
              <Typography>I humbly request all Malaysian Sikhs and Sikhs abroad to become life members of Gurdwara Sahib Putrajaya Malaysia and help complete the “Sikh Road to Putrajaya”
                Please forward this message and Membership/ Donation forms to all Sikhs and Non Sikhs around the globe.</Typography>
              <Typography>
                This SEWA of encouraging the Sikh Sangat to become life members for a noble cause of spreading the Guru’s Bani is an act of love for our Sri Guru Granth Sahib Ji.<br />
                “Wich Duniya  Sev Kamiyeh ta’n Dargeh Baisen  Payieh”<br />
                Thank you Ji and we look forward to your support both moral and financial to help realise this noble Project.
              </Typography>
              <Typography>
                Thank you Ji.<br />
                Waheguru bless 🙏<br /><br />
                Sarbat Dha Bhala<br />
                Maj(Rtd)Jagindar Singh RMAF<br />
                President<br />
                GSPutrajaya Society (GSPS)Malaysia<br />
                Mobile +6019 2705500
              </Typography>
            </AccordionDetails>
          </Accordion>
          </div> */}
          {/* -------------------------------------- */}
          {/* <div className="col-md-7 text-start">

            <h5 className=" text-start text-color-orange fs-2 fw-bolder">
              Gurdwaras in Malaysia
            </h5>
            <p className="lh-lg pt-4  fs-5 text-justify text-wrap" style={{ textAlign: "justify" }}>
              Singhs and Kaurs Society Malaysia, a community of Sewadars (Volunteers) established over the past 19 years wants to serve the Malaysian Indian Communities. We hope to promote connectivity and act as one on bringing the idea of wellness, spirituality and abundance into our lives. Singhs and Kaurs Society Malaysia aims to educate ourselves, our peers and our generation to uphold Indian languages, cultures, religions and thrive to give back to the community as active volunteers.
            </p>
            <p className="lh-lg pt-3  fs-5 text-justify text-wrap">
              Focused on the essential elements of wellbeing. A holistic approach to living life with meaning, vigour and social connectivity.
            </p>
          </div> */}

          <div className="col-md-11">

            <h5 className=" text-center text-color-orange fs-2 fw-bolder">
              Address by the Chairman
            </h5><br />
            {/* <p className="lh-lg pt-4  fs-5 text-justify text-wrap" style={{ textAlign: "justify" }}>
              Singhs and Kaurs Society Malaysia, a community of Sewadars (Volunteers) established over the past 19 years wants to serve the Malaysian Indian Communities. We hope to promote connectivity and act as one on bringing the idea of wellness, spirituality and abundance into our lives. Singhs and Kaurs Society Malaysia aims to educate ourselves, our peers and our generation to uphold Indian languages, cultures, religions and thrive to give back to the community as active volunteers.
            </p>
            <p className="lh-lg pt-3  fs-5 text-justify text-wrap">
              Focused on the essential elements of wellbeing. A holistic approach to living life with meaning, vigour and social connectivity.
            </p> */}
            <Typography className=" fs-4 " sx={{ textAlign: "center" }}>
              🙏Waheguru Ji Ka Khalsa Waheguru Ji Ki Fateh 🙏
            </Typography><br />
            <Typography>
              Sikh Brothers & Sisters Ji.<br />
              Gurdwara Sahib Putrajaya Malaysia<br />
              National Gurdwara of Malaysia.<br />
            </Typography>
            <Typography style={{ textAlign: "Justify" }}>On behalf of the Management Committee I humbly request you to join us on the “Sikh Road 550 to Putrajaya “ in bringing the Sacred Gurbani of Guru Nanak Dev Ji Maharaj to Putrajaya Malaysia.<br />
              The noble project to build the Gurdwara Sahib was officially  launched on 8 Dec 2019. ( 550 Guru Nanak Prakash Year) on a prime piece of land in Presint 5/20 provided by the Government of Malaysia.</Typography>
            <Typography style={{ textAlign: "Justify" }}>Due  to  Covid-19 the Project got  delayed but now with Waheguru’s blessings is back on track. The authorities require us to show a strong membership  base and at least RM One million( 20% of the projected cost) before they grant us permission to start construction. This is the one and only opportunity for the present Sangat of Malaysia to leave a Nishan Sahib In Putrajaya  (the Administrative Capital of Malaysia)  for our future generation to have a place of worship and religious activities.</Typography>
            <Typography style={{ textAlign: "Justify" }}>I humbly request all Malaysian Sikhs and Sikhs abroad to become life members of Gurdwara Sahib Putrajaya Malaysia and help complete the “Sikh Road to Putrajaya”
              Please forward this message and Membership/ Donation forms to all Sikhs and Non Sikhs around the globe.</Typography>
            <Typography style={{ textAlign: "Justify" }}>
              This SEWA of encouraging the Sikh Sangat to become life members for a noble cause of spreading the Guru’s Bani is an act of love for our Sri Guru Granth Sahib Ji.<br />
              “Wich Duniya  Sev Kamiyeh ta’n Dargeh Baisen  Payieh”<br />
              Thank you Ji and we look forward to your support both moral and financial to help realise this noble Project.
            </Typography>
            <Typography style={{ textAlign: "Justify" }}>
              Thank you Ji.<br />
              Waheguru bless 🙏<br /><br />
              Sarbat Dha Bhala<br />
              Maj(Rtd)Jagindar Singh RMAF<br />
              President<br />
              GSPutrajaya Society (GSPS)Malaysia<br />
              Mobile +6019 2705500
            </Typography>
          </div>
          {/* <div
                                  className="col-md-4  border-color-blue  d-flex align-items-center flex-column pt-2"
                                  style={{ height: "550px" }}
                                >
                                  <div className="py-2">
                                    <h4 className="text-bold text-color-orange">HUKUMNAMA</h4>
                                    <p className="text-center fs-lighter">
                                      <span className="color-text-blue">
                                    
                                        {!hukumnamaData ? currentDate : hukumnamaData.hukamnamaDate }
                                      </span>
                                    </p>
                                    <hr />
                                  </div>
                                  <div className="text-center text-md-start overflow-auto h-75 ">
                                  
                                      {!hukumnamaData
                                        ? ( <p> "Today's Hukumnama will be updated soon"</p> )
                                      : !hukumnamaData.hukamnamaText ? ( <img src={`${url}/api/v1/image/${hukumnamaData.photo}`} className="img-fluid" alt="" /> ) : (<p>{hukumnamaData.hukamnamaText}</p>) }
                                  
                                  </div>
                                </div> */}

          {/* <div className="col-md-7 text-start"></div> */}
          {/* <div className="col-md-4 text-center">
                                  {hukumnamaData ? (
                                    <p>
                                      {" "}
                                      If you want to see the full Image of the Hukamnama{" "}
                                      <span onClick={() => hukamNamaImg()} className="text-primary" style={{cursor:'pointer'}}>
                                        click here
                                      </span>{" "}
                                    </p>
                                  ) : (
                                    <p>Hukamnama Image will be updated soon</p>
                                  )}
                                </div> */}
       
        </div>
        <div className="container-fluid col-md-12 px-md-5 ">
          <div className="mt-md-4">
            {/* <hr/> */}
            <h5 className="m-4 text-center text-color-orange fs-2 fw-bolder">
              GURDWARA SAHIB PUTRAJAYA
            </h5>
          </div>
          {/* <div className="container-fluid gurudwara-home-gradient py-2">
        <div className="row d-flex flex-wrap justify-content-evenly mt-3 my-5 ">
          {
            
            gurudwaraMalaysia?.map((data) => {
              return <SingleUpComingEvent data={data} />;
            })
          }
        </div>
      </div> */}
          <p style={{ textAlign: "Justify" }}>
            Singhs and Kaurs Society Malaysia, a community of Sewadars (Volunteers) established over the past 19 years wants to serve the Malaysian Indian Communities. We hope to promote connectivity and act as one on bringing the idea of wellness, spirituality and abundance into our lives. Singhs and Kaurs Society Malaysia aims to educate ourselves, our peers and our generation to uphold Indian languages, cultures, religions and thrive to give back to the community as active volunteers.
          </p>
          <div className="col-md-11 d-flex mb-2"
           style={{ width: "100%", maxheight:"25rem", justifyContent: "center" }}
          >
          <img src="/images/putrajaya-gurdwara-agm-2019_ariel-view_combo_1.jpg" alt="" style={{ width: "100%", maxHeight:"25rem" }} />
          </div>
        
        </div>
        {/* <div className="container-fluid mb-5">
                              <div className="row justify-content-evenly">
                                <div className="col-md-5 text-start mt-5 ">
                                  <h4>List of Donatees</h4>
                                  <p className="fs-5">
                                    {" "}
                                    <span style={{ color: "#FF0505" }}>
                                      ( Want to donate? <ArrowRightCircle />{" "}
                                    </span>
                                    <Link to="/Donate" onClick={goToTop}>
                                      <span className="fs-6" style={{ color: "#0D8353" }}>
                                        Online Payment Gateway)
                                      </span>
                                    </Link>
                                  </p>
                                  <div className="overflow-auto h-75 h-md-50">
                                    <table className="table table-striped table-hover shadow">
                                      <thead style={{ backgroundColor: "#E7740A" }}>
                                        <tr>
                                          <th scope="col">Name</th>
                                          <th scope="col">Amount</th>
                                          <th scope="col">Date</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {donateesData.map((data) => {
                                          return (
                                            <tr>
                                              <td>{data.name}</td>
                                              <td>{data.donationAmount}/- </td>
                                              <td>{data.date}</td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                            <div className="row d-flex justify-content-center justify-content-md-end">
                                    <button
                                    type="button"
                                    class="btn btn-primary bg-button-yellow w-25 me-md-3"
                                    >
                                    See More
                                    </button>
                                  </div>
                            </div>
                                <div className="col-md-5 text-start mt-5 ">
                                  <h4>News and Events</h4>
                                  <p className="fs-5">
                                    {" "}
                                    <span style={{}}>
                                      All Upcoming Events by Gurudwara Sachkhand Sahib{" "}
                                    </span>
                                  </p>
                                  <div className="overflow-auto h-75 h-md-25">
                                    <table className="table table-striped table-hover shadow">
                                      <thead style={{ backgroundColor: "#E7740A" }}>
                                        <tr>
                                          <th scope="col" colspan="3">
                                            List of all News and Gurudwara Events
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {eventsData.map((data) => {
                                          return (
                                            <tr>
                                              <td>{data.eventName}</td>
                                              <td>{data.eventDate}</td>
                                              <td>{data.month}</td>
                                            </tr>
                                          );
                                        })}
                                      
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div> */}
      </div>
    </>
  );
};

export default Home;
