import React from "react";
import { Facebook, Translate, Twitter, Youtube } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import logoFooter from "../../../src/logo.svg"
// import url from "../../url";
import BgImg from "../../../src/headerbg.png"


const Footer = () => {
  
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="container-fluid bg-color">
        <div className="row text-white text-center text-md-start pt-5">
          <div className="col-md-4 d-flex justify-content-center justify-content-md-start align-items-center ">
            {" "}
            <Link to="/" onClick={goToTop}>
              <img
                src={logoFooter}
                className="img-fluid logo-img pb-5 ms-md-5 footer-logo"
                alt=""
                style={{width: "100%",
                        height: "auto",
                        maxWidth: "50%"
              }}
              />{" "}
            </Link>
          </div>
          <div className="col-md-4" style={{ backgroundImage: `url(${BgImg})`, 
                                             backgroundPosition: "center center" , 
                                             backgroundRepeat: "space", 
                                             overflow: "hidden",
                                             }}>
            <h4 className="fw-1">Important links</h4>
            <Link to="/" onClick={goToTop} >
              <p className="hov">Home</p>
            </Link>
            <Link to="/About" onClick={goToTop} >
              <p className="hov">About Us</p>
            </Link>

            <Link to="/Contact_Us" onClick={goToTop}>
              <p className="hov">Contact Us</p>
            </Link>

            <Link to="/UpcomingEvent" onClick={goToTop}>
              <p className="hov">Events</p>
            </Link>

            <Link to="/Basic_Articles" onClick={goToTop}>
              <p className="hov">Sikhism</p>
            </Link>

            <Link to="/sri-guru-nanak-ji" onClick={goToTop}>
              <p className="hov">Dharam Prachar</p>
            </Link>


            {/* <Link to="/Donate" onClick={goToTop}>
              <p className="hov">Donation</p>
            </Link> */}


            <Link to="/maingurudwara" onClick={goToTop}>
              <p className="hov">Gurdwara in Malaysia</p>
            </Link>

          </div>
          <div className="col-md-4">
            <h4 className="fw-1">Address</h4>
            <p class="lh-sm">
              43, Jalan P8B/1, Presint 8,
              <br /> 62250 W.P Putrajaya,
              <br /> Malaysia
            </p>
            <h4 className="fw-1">Helpline No.</h4>
            <p>+60183176092</p>
            <h4 className="fw-1">Follow Us</h4>
            <p>
              <a
                href="https://www.facebook.com/gurdwarasahib.putrajaya.3"
                className="fs-4 me-2"
                target="_blank"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/singhskaurs.my/"
                className="fs-4 me-2"
                target="_blank"
              >
                <Twitter />
              </a>
              <a
                href="https://www.youtube.com/channel/UCr5wdFvtC7tsCO2j58W5Gyw"
                className="fs-4 me-2"
                target="_blank"
              >
                <Youtube />
              </a>
            </p>

            <p class="lh-sm">

              <Link to='privacy-policy' onClick={goToTop} > Privacy Policy </Link>   / <Link to='terms-&-conditions' onClick={goToTop}>Terms & Conditions</Link>
            </p>




          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
