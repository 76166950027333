import React from "react";
import Banners from "../reusableComponents/Banners";

import {
  PersonBoundingBox,
  Signpost,
} from "react-bootstrap-icons";
import SingleUpComingEvent from "../reusableComponents/SingleUpComingEvent";
import { aboutGurudwara } from "../reusableComponents/DataApi";
const About = () => {
  return (
    <>
      <Banners title="" />
      <div className="container-fluid">
        <div className="row justify-content-md-start p-2 p-md-3 ">
          <div className="col-md-12 text-start">
            <h3 className="text-color-orange mb-2 pt-md-3 px-md-4" >Executive Committee Members</h3>
            <h3 className="text-color-#212529 mb-2 pt-md-3 px-md-4" style={{ fontSize: "1.1rem" }}>Initial founders who are actively engaged in 2023 activities are:</h3>
            <div className="table-responsive"></div>
            
            <div class="table-responsive p-md-4">
              <table class="table table-striped">
                <thead className="text-white" style={{ backgroundColor: "#E7740A" }}>
                  <tr>
                    <th scope="col-md-4" style={{ width: "50%" }}>{" "}
                      <PersonBoundingBox /> <span className="ms-2">
                        Name
                      </span>{" "}</th>
                    <th scope="col" style={{ width: "25%" }}>{" "}
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-open" viewBox="0 0 16 16">
                        <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                      </svg>
                      <span className="ms-2" >
                        E-Mail
                      </span>{" "}</th>
                    <th scope="col" style={{ width: "25%" }}>{" "}
                      <Signpost /> <span className="ms-2">
                        Post
                      </span>{" "}</th>

                  </tr>
                </thead>
                <tbody>
                <tr >
                  <td>Jaginder Singh A/L Pream Singh, JMW,KMN,PJK, PJM, MsyI,</td>
                  <td>jagpurba@yahoo.com</td>
                  <td>President</td>
                </tr>
                <tr>
                  <td>Sarjeet Kaur A/P Sundar Singh PJM ,</td>
                  <td>sarjeetkaur@gmail.com</td>
                  <td>Vice President</td>
                </tr>
                <tr>
                  <td>Kirpal Singh A/L Gurdarshan Singh	</td>
                  <td>kirrav@yahoo.com</td>
                  <td>General Secretary</td>
                </tr>
                  
                </tbody>
              </table>
            </div>
                   
            <h3 className="text-color-#212529 pt-3 mb-2 pt-md-3 px-md-4" style={{ fontSize: "1.1rem" }}>The 2019 project by SKSM is dependent on the guidance from the 2019-2021 working committee (Sewadar’s), as listed below:</h3>
            <div class="table-responsive p-md-4">
              <table class="table table-striped">
                <thead className="text-white" style={{ backgroundColor: "#E7740A" }}>
                  <tr>
                  <th scope="col" style={{ width: "50%" }}>
                    {" "}
                    <PersonBoundingBox /> <span className="ms-2">
                      Name
                    </span>{" "}
                  </th>
                  <th scope="col" style={{ width: "25%" }}>
                    {" "}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-open" viewBox="0 0 16 16">
                      <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                    </svg>
                    <span className="ms-2">
                      E-Mail
                    </span>{" "}
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                    {" "}
                    <Signpost /> <span className="ms-2">
                      Post
                    </span>{" "}

                  </th>

                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Jaginder Singh A/L Pream Singh, JMW,KMN,PJK, PJM, MsyI</td>
                  <td>jagpurba@yahoo.com</td>
                  <td>President</td>
                </tr>
                <tr>
                  <td>Dr Sangeeta Kaur, PhD (Monash, Malaysia); MSsc(USM, Malaysia)	</td>
                  <td>sangeetakaur.sran@gmail.com</td>
                  <td>Deputy President</td>
                </tr>
                <tr>
                  <td>Ms Parminder Kaur A/P Teja Singh, Chartered Islamic Finance
                    Professional (INCEIF), BSc (Applied Psy Counselling- University of Arts, Australia)	</td>
                  <td>minder.gill@gmail.com</td>
                  <td>Treasurer</td>
                </tr>
                <tr>
                  <td>Kirpal Singh A/L Gurdarshan Singh, Assist	</td>
                  <td>kirrav@yahoo.com</td>
                  <td>Secretary</td>
                </tr>
                <tr>
                  <td>Balvinder Kaur Dhaliwal, fresh high school graduate, representing youth perspective.</td>
                  <td>balvindhaliwal3105@gmail.com</td>
                  <td>Secretary</td>
                </tr>
                <tr>
                  <td>Paramjit Singh A/L Pertap Singh (Ex-MAS), representing retirees’ perspective</td>
                  <td>paramjitdhesi@hotmail.com</td>
                  <td>Member Captain</td>
                </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row text-start pt-0 p-2 p-md-5 pt-md-2" style={{ textAlign: "justify" }}>
          <h3 className="text-color-orange pt-3 mb-4 mb-md-5">Objectives</h3>
          <p style={{ fontSize: "21px", fontWeight: '530', textAlign: "justify" }}>Gurdwara Sahib Putrajaya is a religious society,  which has taken action with the aim of promoting all religions,
            propaganda, priority medical facilities for the children of the townspeople, among the members of the Sikh community as well as the Punjabi citizens. Its other purpose is as follows- </p>
          <p style={{ fontSize: "19px", textAlign: "justify" }}>1. To make appropriate efforts for the social, cultural, moral, educational, intellectual, character, creative and technical development and progress of all the citizens mainly the Sikh community and the Punjabi community.</p>
          <p style={{ fontSize: "19px", textAlign: "justify" }}>2. Establishment of welfare works for the community, medical services To make available,
            religious thoughts, reading of religious texts and thinking on them Organizing lecture series and other programs as per requirement.</p>
          <p style={{ fontSize: "19px", textAlign: "justify" }}>3. Organizing special religious programs on the occasion of religious festivals.</p>
          <p style={{ fontSize: "19px", textAlign: "justify" }}>4. To promote goodwill / brotherhood among all the residents of the country. encourage .</p>
          <p style={{ fontSize: "19px", textAlign: "justify" }}>5. For the purpose of promoting national integration Organizing special events.</p>
          <p style={{ fontSize: "19px", textAlign: "justify" }}>6. Useful knowledge for the development of religious knowledge, Panchabi literature and art Storing free books, papers and magazines for the promotion of and establishment of library/reading room</p>
          <p style={{ fontSize: "19px", textAlign: "justify" }}>7. Printing and publishing by creating religious knowledge and Punjabi literature and distributing it.</p>
        </div>
      </div>

      {/* <h3 className="text-center fw-bold text-color-orange-dark">Other</h3>
      <div className="container-fluid  m-2 py-2 " >
        <div className="row d-flex flex-wrap justify-content-evenly mb-5">
          {
           
            aboutGurudwara.map((data) => {
              return <SingleUpComingEvent data={data} />;
            })
          }
        </div>
      </div> */}

    </>
  );
};

export default About;
