exports.events = [
  { id: "events", img: "/images/1Event.png", footerText: "January" },
  { id: "events", img: "/images/2Event.png", footerText: "February" },
  { id: "events", img: "/images/3Event.png", footerText: "March" },
  { id: "events", img: "/images/4Event.png", footerText: "April" },
  { id: "events", img: "/images/5Event.png", footerText: "May" },
  { id: "events", img: "/images/6Event.png", footerText: "June" },
  { id: "events", img: "/images/7Event.png", footerText: "July" },
  { id: "events", img: "/images/8Event.png", footerText: "August" },

  { id: "events", img: "/images/9Event.png", footerText: "September" },
  { id: "events", img: "/images/10Event.png", footerText: "October" },
  { id: "events", img: "/images/11Event.png", footerText: "November" },
  { id: "events", img: "/images/12Event.png", footerText: "December" },
];

exports.gurudwaraMalaysia  = [
  {
    place:"outer",
    id: "gurudwara",
    img: "/images/Gurdwara-sahib-sg-petani.jpg",
    footerText: "Gurudwara Sahib SG. Petani",
    url: "https://www.facebook.com/spgurdwara",
  },
  {
    place:"outer",
    id: "gurudwara",
    img: "/images/gdm2.jpeg",
    footerText: "Satnam Vaheguru",
    url: "https://www.facebook.com/SatnamVaheguru/",
  },
  {
    place:"outer",
    id: "gurudwara",
    img: "/images/GurudwaraSahibKgPandan.jpg",
    footerText: "Gurudwara Sahib KG. Pandan",
    url: "https://www.facebook.com/gurdwarakgpandansettlement/",
  },
  {
    place:"outer",
    id: "gurudwara",
    img: "/images/gdm4.jpeg",
    footerText: "Gurudwara in kuching",
    url: "https://www.facebook.com/people/Gurdwara-Sahib-Kuching/100064725124706/?paipv=0&eav=AfY_tyxCWYBFv1_inE8WQDln4CzzAT6sNVLjcK8bHq0kzF2gq07Py0R6jZdWpuHVe4g&_rdr",
  },
  {
    place:"outer",
    id: "gurudwara",
    img: "/images/gdm5.jpeg",
    footerText: "Gurudwara Rawang",
    url: "https://www.facebook.com/Gurdwara.Rawang/",
  },
  {
    place:"outer",
    id: "gurudwara",
    img: "/images/Gurdwara-Sahib-Labuan.jpg",
    footerText: "Gurudwara Sahib Labuan",
    url: "http://www.pulaulabuan.com/gurdwara-sahib-temple/",
  },
];
exports.aboutGurudwara = [

    {   id:"about",
        img:"/images/donate.png",
        footerText: "Donate",
        link:"/Donate"
        
    },
    {   id:"about",
        img:"/images/upcomingEvents.png",
        footerText: "Upcoming Events",
        link:"/UpcomingEvent"
    },
   
    {   id:"about",
        img:"/images/Gurdwara-Sahib-Labuan.jpg",
        footerText: "Gurudwaras in Malaysia",
        link:"/maingurudwara"
    },
   
]

exports.gurudwaraCollege = [
  {
    id: "college",
    img: "/images/colleges.png",
    title: "",
  },
];

exports.gurudwara = [
  {
    id: "college",
    img: "/images/gurudwaraImg.png",
    title: "",
  },
];

exports.home = [
  {
    id:'home',
    img:"/images/homepage-image.png",
    title:""
  }
];

exports.mainguru = [
  {
    city: "JOHOR",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Muar",
        url: 	"https://www.facebook.com/gurdwarasahibmuar",
      },
      {
        gurdwara_name: "Gurdwara Sahib Segamat",
        url:	"https://www.facebook.com/pages/Sikh-Gurdwara-Segamat/278219198946314",
      },
      {
        gurdwara_name: "Gurdwara Sahib Babe ke Guru Ram Das World, Machap	",
        url:	"https://www.facebook.com/pages/Gurdwara-Sahib-Machap-Babe-Ke/1836557266631868",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kluang	",
        url:	"https://www.facebook.com/pages/category/Community/Gurdwara-Sahib-Kluang-177595092277138/http://gurudwaraballeela.com/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Pontian, Johor	",
        url:	"https://www.facebook.com/pages/Gurdwara-Sahib-Pontian/1631713323762877",
      },
      {
        gurdwara_name: "Gurdwara Sahib Johor Bahru, Johor	",
        url:	"https://www.facebook.com/pages/Gurdwara-Sahib-Johor-Bahru/282169775760982",
      },
    ]
  },
  {
    city: "KUALA LUMPUR",
    gurudwaraM: [
      {
        gurdwara_name: "Wadda Gurdwara Sahib Jalan Kampung	",
        url: 	"https://www.facebook.com/OurGurdwara",
      },
      {
        gurdwara_name: "Gurdwara Sahib Jinjang	",
        url:	"https://www.facebook.com/pages/Gurdwara-Sahib-Jinjang/154219998543710",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kampung Pandan Settlement, Kuala Lumpur	",
        url:	"https://www.facebook.com/pages/category/Nonprofit-Organization/Gurdwara-Sahib-Kg-Pandan-settlement-359349354179472/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kuyow, Kuala Lumpur		",
        url:	"https://www.facebook.com/GurdwaraSahibKuyow/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Mainduab, Kuala Lumpur	",
        url:	"https://www.facebook.com/pages/Gurdwara-Sahib-Mainduab-Jalan-Pudu/187962334646881",
      },
      {
        gurdwara_name: "Gurdwara Sahib Police, Kuala Lumpur	",
        url:	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Police Depot, Kuala Lumpur	",
        url:	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Police Jalan Parliament, Kuala Lumpur	",
        url:	"https://www.facebook.com/pages/category/Community/Gurdwara-Sahib-Polis-High-StreetParlimen-246775495382426/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Central Workshops Sentul, Kuala Lumpur",
        url:	"https://www.facebook.com/TattKhalsaDiwan",
      },
      {
        gurdwara_name: "Gurdwara Sahib, Titiwangsa Kuala Lumpur, Malaysia	",
        url:	"https://www.facebook.com/GSTitiwangsa",
      },
      {
        gurdwara_name: "Gurdwara Sahib Majha Diwan Malaya",
        url:	"https://www.facebook.com/pages/Gurdwara-Sahib-Sentul/2308355615905247",
      },
    ]
  },
  {
    city: "LABUAN",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Labuan",
        url: 	"http://www.pulaulabuan.com/gurdwara-sahib-temple/",
      },
      
    ]
  },
  {
    city: "PAHANG",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Brinchang, Cameron Highlands, Pahang",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Brinchang-Cameron-Highlands/247789175258896",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bentong, Pahang",
        url:	"https://www.facebook.com/groups/147524565294769/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kuala Lipis, Pahang",
        url:	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tanah Rata, Pahang	",
        url:	"https://www.facebook.com/visitgurdwaramalaysia/posts/gurdwara-sahib-tanah-rata-pahangestablished-1931status-activeaddress-jalan-jasar/1017706291743154/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Raub, Pahang",
        url:	"https://www.facebook.com/gurdwarasahibraub.pahang",
      },
      {
        gurdwara_name: "Gurdwara Sahib Mentakab, Pahang	",
        url:	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib (Sikh Temple), Kuantan",
        url:	"https://www.facebook.com/pages/Gurdwara-Kuantan/425199847494301",
      },
    ]
  },
  {
    city: "PERAK",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Khalsa Dharmik Sabha, Parit Buntar, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Pokok Assam, Taiping, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Taiping, Perak",
        url: 	"https://www.facebook.com/pages/category/Local-Business/GURDWARA-SAHIB-TAIPING-PERAK-10150113462695383/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Sitiawan, Perak",
        url: 	"https://www.facebook.com/groups/248545551884849/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Sungei Siput Utara, Perak",
        url: 	"https://www.facebook.com/gurdwara.sahib.395",
      },
      {
        gurdwara_name: "Gurdwara Sahib Chemor, Perak",
        url: 	"https://m.facebook.com/pages/Gurudwara-Sahib-Chemor-Perak/321810931682723",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kampong Kepayang, Ipoh, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tanjong Rambutan, Perak",
        url: 	"https://m.facebook.com/pages/Gurdwara-Sahib-Tanjung-Rambutan/839113769572167",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kuala Kangsar, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Shaheed Ganj Sahib, Kaniunting,Perak	",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bruas, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bagan Serai, Perak",
        url: 	"https://www.facebook.com/pages/category/Religious-Organization/Gurdwara-Sahib-Bagan-Serai-190124354373800/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Jelapang, Ipoh, Perak",
        url: 	"https://www.facebook.com/gurdwarasahibjelapang/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Sikh Dharmak Sabha,Ipoh, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Gunong Rapat, Ipoh, Perak",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Gunung-Rapat/204609563415665",
      },
      {
        gurdwara_name: "Gurdwara Sahib Buntong, Ipoh, Perak",
        url: 	"https://www.facebook.com/gurdwara.sahibbuntong",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tambun, Ipoh, Perak",
        url: 	"https://www.facebook.com/gurdwarasahibtambun.ipoh",
      },
      {
        gurdwara_name: "Gurdwara Sahib Menglembu Regrouping Area, Ipoh, Perak	",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Lahat, Ipoh, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Gopeng, Perak",
        url: 	"https://www.facebook.com/gurdwarasahibgopeng.perak.1",
      },
      {
        gurdwara_name: "Gurdwara Sahib Malim Nawar, Perak",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Malim-Nawar/934632426727836",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tanjong Tuallang, Perak	",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Changkat Tin, Perak",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Changkat-Tin-Kampung-Timah/175419599691251",
      },
      {
        gurdwara_name: "Gurdwara Sahib Ayer Papan, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tronoh, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Pusing, Perak	",
        url: 	"https://www.facebook.com/gurdwarasahib.pusing.9",
      },
      {
        gurdwara_name: "Gurdwara Sahib Siputeh, Ipoh, Perak",
        url: 	"https://www.facebook.com/pages/category/Sikh-Temple/Gurdwara-Sahib-Siputeh-GSS-1887544421538715/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Menglembu, Jalan Lahat, Ipoh, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bercham, Ipoh, Perak",
        url: 	"https://www.facebook.com/gurdwarasahibbercham.ipoh",
      },
      {
        gurdwara_name: "Gurdwara Sahib Greentown, Ipoh, Perak",
        url: 	"https://www.facebook.com/gurdwarasahibgreentown.ipoh",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kampar, Perak",
        url: 	"https://www.facebook.com/GurdwaraSahebKamparPerak/?rf=295375973862423",
      },
      {
        gurdwara_name: "Gurdwara Sahib Teluk Intan, Perak",
        url: 	"https://www.facebook.com/pages/category/Religious-Center/Shri-Gurdwara-Sahib-Teluk-Intan-142867225780912/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Slim River, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Changkat, Batu Gajah, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Batu Gujah, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Sri Guru Singh Sabha, Gopeng, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Malay States Guide, Taiping, Perak",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Police, Ipoh, Perak",
        url: 	"https://www.facebook.com/pages/Police-Gurdwara-Ipoh/360160010682310",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tanjong Malim, Perak",
        url: 	"https://www.facebook.com/gurdwarasahib.tanjongmalim",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bidor, Perak	",
        url: 	"https://www.facebook.com/GurdwaraSahibBidor/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tapah, Perak	",
        url: 	"https://www.facebook.com/gurdwara.tapahperak",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tronoh Mines, Kampar, Perak		",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Tronoh-Mines/147704715840016",
      },
    ]
  },
  {
    city: "KEDAH",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Kulim,Kedah	",
        url: 	"https://gurdwarasahibkulim.wixsite.com/gskulim",
      },
      {
        gurdwara_name: "Gurdwara Sahib Sungei Petani,Kedah	",
        url: 	"https://www.facebook.com/spgurdwara",
      },
      {
        gurdwara_name: "Gurdwara Sahib Alor Star, Kedah	",
        url: 	"https://www.facebook.com/gurdwarasahibalorsetar.setar",
      },
    ]
  },
  {
    city: "KELANTAN",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Tumpat, Kelantan",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kota Bahru,Kelantan",
        url: 	"https://www.facebook.com/gskbkelantan/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kuala Krai, Kelantan	",
        url: 	"https://www.facebook.com/534204900093298/posts/gurdwara-sahib-kuala-krai-kelantanestablished-1956-current-site-status-to-be-con/912470148933436/",
      },
    ]
  },
  {
    city: "MALACCA",
    gurudwaraM: [
      {
        gurdwara_name: '"Gurdwara Sahib Malacca"',
        url: 	"https://www.facebook.com/GurdwaraSahibMelaka/",
      },
      
    ]
  },
  {
    city: "NEGERI SEMBILAN",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Mantin, Negeri Sembilan	",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Mantin/154734381250324",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kuala Klavang, Jelebu, Negeri Sembilan	",
        url: 	"https://www.facebook.com/visitgurdwaramalaysia/posts/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Port Dickson, Negeri Sembilan	",
        url: 	"gurdwara-sahib-kuala-klawang-jelebu-negeri-sembilanestablished-1911status-active/1152185911628524/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Seremban, Negeri Sembilan	",
        url: 	"https://www.facebook.com/groups/300244386714249/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kuala Pilah,Negeri Sembilan	",
        url: 	"https://www.facebook.com/gurdwara.sahib.seremban",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tampin, Negeri Sembilan	",
        url: 	"https://gskualapilah.wixsite.com/gskp",
      },
    ]
  },
  {
    city: "PENANG",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Khalsa Dharmak Jatha, Penang	",
        url: 	"https://www.facebook.com/pages/Khalsa-Dharmik-Jatha-Gurdwara/210365382369255",
      },
      {
        gurdwara_name: "Gurdwara Sahib Sikh Police, Penang	",
        url: 	"https://www.facebook.com/Police-Gurdwara-Sahib-Penang-179921792057882/",
      },
      {
        gurdwara_name: "Wadda Gurdwara Sahib, Penang	",
        url: 	"https://www.facebook.com/waddagurdwarasahib.penang",
      },
      {
        gurdwara_name: "Gurdwara Sahib Perai, Penang	",
        url: 	"https://www.facebook.com/gurdwarasahib.prai",
      },
      {
        gurdwara_name: "Gurdwara Sahib Butterworth, Penang	",
        url: 	"https://www.facebook.com/gurdwara.butterworth",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bukit Mertajam, Penang	",
        url: 	"https://www.facebook.com/pages/Gurudwara-Sahib-Butterworth/172539569575377?nr",
      },
    ]
  },
  {
    city: "PERLIS",
    gurudwaraM: [
      {
        gurdwara_name: "PERLIS Gurdwara Sahib Kangar, Perlis	",
        url: 	"https://www.facebook.com/groups/gurdwarasahibkangar/",
      },
      
    ]
  },
  {
    city: "SUBAH",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Singh Sabha, Lahad Datu, Sabah	",
        url: 	"https://www.allaboutsikhs.com/gurudwaras-in-malaysia/gurudwara-sahib-singh-sabha-lahad-datu-sabah",
      },
      {
        gurdwara_name: "Gurdwara Sahib Tawau, Sabah",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kota Kinabalu, Sabah	",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Sikh-Temple-Kota-Kinabalu/107514082689425",
      },
      {
        gurdwara_name: "Gurdwara Sahib Sandakan, Sabah",
        url: 	"",
      },
    ]
  },
  {
    city: "SARAWAK",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Kuching, Sarawak	",
        url: 	"https://www.facebook.com/Gurdwara-Sahib-Kuching-169683883055071/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Sibu, Sarawak	",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Sibu/280372742378911",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bau, Sarawak	",
        url: 	"https://www.allaboutsikhs.com/gurudwaras-in-malaysia/gurudwara-sahib-bau-sarawak",
      },
      {
        gurdwara_name: "Gurdwara Sahib Miri, Sarawak",
        url: 	"",
      },
    ]
  },
  {
    city: "SELANGOR",
    gurudwaraM: [
      {
        gurdwara_name: "Gurdwara Sahib Puchong,Selangor	",
        url: 	"https://www.facebook.com/Gurdwara.Sahib.Puchong/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kalumpang, Selangor",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kuala Kubu Baru, Selangor	",
        url: 	"https://www.facebook.com/pg/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Berjuntai Tin, Selangor	",
        url: 	"GURDWARA-SAHIB-BABA-VIR-SINGH-HULU-YAM-BHARU-SELANGOR-279350177248/posts/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Klang, Selangor	",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Shah Alam, Selangor	",
        url: 	"https://www.facebook.com/Gurdwara-Sahib-Port-Klang-176258862556240/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Lembah Jaya, Ampang, Selangor	",
        url: 	"https://www.facebook.com/gssabc",
      },
      {
        gurdwara_name: "Gurdwara Sahib Kajang, Selangor",
        url: 	"	https://www.facebook.com/GSLJA/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Batu Arang, Selangor	",
        url: 	"https://www.facebook.com/Gurdwara-Sahib-Kajang-1445920619025449/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Ampang, Selangor",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Guru Ram Das, Banting, Selangor	",
        url: 	"https://www.facebook.com/gurdwarasahibampang",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bukit Beruntung, Selangor",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Petaling Jaya",
        url: 	"",
      },
      {
        gurdwara_name: "Gurdwara Sahib Rawang	",
        url: 	"https://www.facebook.com/pjgurdwara",
      },
      {
        gurdwara_name: "Gurdwara Sahib Bandar Sunway	",
        url: 	"https://www.facebook.com/groups/115924748425720/",
      },
      {
        gurdwara_name: "Gurdwara Sahib Nanaksar, Serendah	",
        url: 	"https://www.facebook.com/pages/Gurdwara-Sahib-Bandar-Sunway/143580616341529",
      },
      {
        gurdwara_name: "Malaysian Sikh Establishments	",
        url: 	"https://www.facebook.com/gurudwara.nanaksarserendah",
      },
      {
        gurdwara_name: "Sikh Naujawan Sabha Malaysia	",
        url: 	"",
      },
      {
        gurdwara_name: "Malaysian Gurdwaras Council	",
        url: 	"https://snsm.org.my/",
      },
      {
        gurdwara_name: "Persatuan Sikh Malaysia	",
        url: 	"http://www.mgc.org.my/malaysian-gurdwaras.html",
      },
      {
        gurdwara_name: "Khalsa Diwan Malaysia	",
        url: 	"https://www.msu.com.my/",
      },
      
    ]
  }
]
