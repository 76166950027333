
import React from "react";
import Banners from "../reusableComponents/Banners";
import BannerUsable from "../reusableComponents/BannerUsable";
import { gurudwara, mainguru } from "../reusableComponents/DataApi";
import "./maingurudwara.css";
import MainBanner from "../reusableComponents/MainBanner";
import { Cursor, Link } from "react-bootstrap-icons";

const Maingurudwara = () => {
  return (
    <>

      <MainBanner data={gurudwara} />

      <div className="container mt-5 mb-5" style={{ width: { md: "450px", sm: "350px", xs: "250px" }, overflowX: "auto" }}>
        {mainguru.map((dataGuru) => {
          const { city, gurudwaraM } = dataGuru;
          return (
            <>
              <div className="johar" style={{
                backgroundColor: '#F97201',
                color: 'white',
                width: '200px',
                height: '40px',
                marginBottom: "10px",
                textAlign: "center",
                justifyContent: "center",
                marginLeft: '-1px'

              }}>
                {city}</div >
              {/* <div className="row"  > */}
                {/* <table className="table table-striped guru-div" >
                    <thead className="thead">
                      <tr style={{ backgroundColor: '#F97201', color: 'white' }}>
                        <th scope="col" style={{width: "50%"}}>Name</th>
                        <th scope="col" style={{width: "50%"}}>Url</th>
                      </tr>
                    </thead>
                    <tbody>

                      {gurudwaraM.map((data2) => {
                        const { gurdwara_name, url } = data2;
                        return (

                          <tr>
                            <td>{gurdwara_name}</td>
                            
                            <td><a href={url} target="_blank" className="urlguru">{url}</a></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table> */}

                <div class="table-responsive">
                  <table class="table">
                    <thead className="thead">
                      <tr style={{ backgroundColor: '#F97201', color: 'white' }}>
                        <th scope="col-5" style={{ width: "50%" }}>Name</th>
                        <th scope="col-5" style={{ width: "50%" }}>Url</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gurudwaraM.map((data2) => {
                        const { gurdwara_name, url } = data2;
                        return (

                          <tr>
                            <td>{gurdwara_name}</td>

                            <td><a href={url} target="_blank" className="urlguru">{url}</a></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              {/* </div> */}
            </>
          )
        })}
      </div >

    </>
  )
}

export default Maingurudwara